<script setup lang="ts">
const app = useAppStore();
</script>

<template>
  <div>
    <slot />
    <div
      v-if="app.errors?.length"
      class="w-96 ml-50 fixed bottom-8 right-8 rounded-lg shadow-xl shadow-nred-1/50 bg-ngray-6 p-2 text-ngray-2 border-4 border-nred-5"
    >
      <div class="flex justify-between">
        <div class="font-medium mb-2 text-lg">Errors occurred:</div>
        <icon name="mdi:close" class="text-lg hover:cursor-pointer" @click="app.clearErrors()" />
      </div>
      <ul class="list-disc list-inside">
        <li v-for="e in app.errors" :key="e">
          {{ e }}
        </li>
      </ul>
    </div>
  </div>
</template>
