<script setup lang="ts">
import { useMouseCoords } from "../composables/directives";
const { t } = useI18n();
const { updateCoords } = useMouseCoords();
const route = useRoute();
const header = computed(() => (route?.meta.header ?? "Header") as string);
const subheader = computed(() => (route?.meta.subheader ?? "Subheader") as string);
</script>

<template>
  <main v-mouse-coords="updateCoords">
    <wait-overlay>
      <error-overlay>
        <page-header />
        <headered-content-section :header="t(header)" :subheader="t(subheader)">
          <slot />
        </headered-content-section>
      </error-overlay>
    </wait-overlay>
  </main>
</template>
